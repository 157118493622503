var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! Sortable 1.15.0 - MIT | git://github.com/SortableJS/Sortable.git */
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  function e(e, t) {
    var n,
        o = Object.keys(e);
    return Object.getOwnPropertySymbols && (n = Object.getOwnPropertySymbols(e), t && (n = n.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), o.push.apply(o, n)), o;
  }

  function M(o) {
    for (var t = 1; t < arguments.length; t++) {
      var i = null != arguments[t] ? arguments[t] : {};
      t % 2 ? e(Object(i), !0).forEach(function (t) {
        var e, n;
        e = o, t = i[n = t], n in e ? Object.defineProperty(e, n, {
          value: t,
          enumerable: !0,
          configurable: !0,
          writable: !0
        }) : e[n] = t;
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(o, Object.getOwnPropertyDescriptors(i)) : e(Object(i)).forEach(function (t) {
        Object.defineProperty(o, t, Object.getOwnPropertyDescriptor(i, t));
      });
    }

    return o;
  }

  function o(t) {
    return (o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    })(t);
  }

  function a() {
    return (a = Object.assign || function (t) {
      for (var e = 1; e < arguments.length; e++) {
        var n,
            o = arguments[e];

        for (n in o) Object.prototype.hasOwnProperty.call(o, n) && (t[n] = o[n]);
      }

      return t;
    }).apply(this || _global, arguments);
  }

  function i(t, e) {
    if (null == t) return {};

    var n,
        o = function (t, e) {
      if (null == t) return {};

      for (var n, o = {}, i = Object.keys(t), r = 0; r < i.length; r++) n = i[r], 0 <= e.indexOf(n) || (o[n] = t[n]);

      return o;
    }(t, e);

    if (Object.getOwnPropertySymbols) for (var i = Object.getOwnPropertySymbols(t), r = 0; r < i.length; r++) n = i[r], 0 <= e.indexOf(n) || Object.prototype.propertyIsEnumerable.call(t, n) && (o[n] = t[n]);
    return o;
  }

  function r(t) {
    return function (t) {
      if (Array.isArray(t)) return l(t);
    }(t) || function (t) {
      if ("undefined" != typeof Symbol && null != t[Symbol.iterator] || null != t["@@iterator"]) return Array.from(t);
    }(t) || function (t, e) {
      if (t) {
        if ("string" == typeof t) return l(t, e);
        var n = Object.prototype.toString.call(t).slice(8, -1);
        return "Map" === (n = "Object" === n && t.constructor ? t.constructor.name : n) || "Set" === n ? Array.from(t) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? l(t, e) : void 0;
      }
    }(t) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }();
  }

  function l(t, e) {
    (null == e || e > t.length) && (e = t.length);

    for (var n = 0, o = new Array(e); n < e; n++) o[n] = t[n];

    return o;
  }

  function t(t) {
    if ("undefined" != typeof window && window.navigator) return !!navigator.userAgent.match(t);
  }

  var y = t(/(?:Trident.*rv[ :]?11\.|msie|iemobile|Windows Phone)/i),
      w = t(/Edge/i),
      s = t(/firefox/i),
      u = t(/safari/i) && !t(/chrome/i) && !t(/android/i),
      n = t(/iP(ad|od|hone)/i),
      c = t(/chrome/i) && t(/android/i),
      d = {
    capture: !1,
    passive: !1
  };

  function h(t, e, n) {
    t.addEventListener(e, n, !y && d);
  }

  function f(t, e, n) {
    t.removeEventListener(e, n, !y && d);
  }

  function p(t, e) {
    if (e && (">" === e[0] && (e = e.substring(1)), t)) try {
      if (t.matches) return t.matches(e);
      if (t.msMatchesSelector) return t.msMatchesSelector(e);
      if (t.webkitMatchesSelector) return t.webkitMatchesSelector(e);
    } catch (t) {
      return;
    }
  }

  function N(t, e, n, o) {
    if (t) {
      n = n || document;

      do {
        if (null != e && (">" !== e[0] || t.parentNode === n) && p(t, e) || o && t === n) return t;
      } while (t !== n && (t = (i = t).host && i !== document && i.host.nodeType ? i.host : i.parentNode));
    }

    var i;
    return null;
  }

  var g,
      m = /\s+/g;

  function I(t, e, n) {
    var o;
    t && e && (t.classList ? t.classList[n ? "add" : "remove"](e) : (o = (" " + t.className + " ").replace(m, " ").replace(" " + e + " ", " "), t.className = (o + (n ? " " + e : "")).replace(m, " ")));
  }

  function P(t, e, n) {
    var o = t && t.style;

    if (o) {
      if (void 0 === n) return document.defaultView && document.defaultView.getComputedStyle ? n = document.defaultView.getComputedStyle(t, "") : t.currentStyle && (n = t.currentStyle), void 0 === e ? n : n[e];
      o[e = !(e in o || -1 !== e.indexOf("webkit")) ? "-webkit-" + e : e] = n + ("string" == typeof n ? "" : "px");
    }
  }

  function v(t, e) {
    var n = "";
    if ("string" == typeof t) n = t;else do {
      var o = P(t, "transform");
    } while ((o && "none" !== o && (n = o + " " + n), !e && (t = t.parentNode)));
    var i = window.DOMMatrix || window.WebKitCSSMatrix || window.CSSMatrix || window.MSCSSMatrix;
    return i && new i(n);
  }

  function b(t, e, n) {
    if (t) {
      var o = t.getElementsByTagName(e),
          i = 0,
          r = o.length;
      if (n) for (; i < r; i++) n(o[i], i);
      return o;
    }

    return [];
  }

  function O() {
    var t = document.scrollingElement;
    return t || document.documentElement;
  }

  function k(t, e, n, o, i) {
    if (t.getBoundingClientRect || t === window) {
      var r,
          a,
          l,
          s,
          c,
          u,
          d = t !== window && t.parentNode && t !== O() ? (a = (r = t.getBoundingClientRect()).top, l = r.left, s = r.bottom, c = r.right, u = r.height, r.width) : (l = a = 0, s = window.innerHeight, c = window.innerWidth, u = window.innerHeight, window.innerWidth);
      if ((e || n) && t !== window && (i = i || t.parentNode, !y)) do {
        if (i && i.getBoundingClientRect && ("none" !== P(i, "transform") || n && "static" !== P(i, "position"))) {
          var h = i.getBoundingClientRect();
          a -= h.top + parseInt(P(i, "border-top-width")), l -= h.left + parseInt(P(i, "border-left-width")), s = a + r.height, c = l + r.width;
          break;
        }
      } while (i = i.parentNode);
      return o && t !== window && (o = (e = v(i || t)) && e.a, t = e && e.d, e && (s = (a /= t) + (u /= t), c = (l /= o) + (d /= o))), {
        top: a,
        left: l,
        bottom: s,
        right: c,
        width: d,
        height: u
      };
    }
  }

  function R(t, e, n) {
    for (var o = A(t, !0), i = k(t)[e]; o;) {
      var r = k(o)[n];
      if (!("top" === n || "left" === n ? r <= i : i <= r)) return o;
      if (o === O()) break;
      o = A(o, !1);
    }

    return !1;
  }

  function X(t, e, n, o) {
    for (var i = 0, r = 0, a = t.children; r < a.length;) {
      if ("none" !== a[r].style.display && a[r] !== Bt.ghost && (o || a[r] !== Bt.dragged) && N(a[r], n.draggable, t, !1)) {
        if (i === e) return a[r];
        i++;
      }

      r++;
    }

    return null;
  }

  function Y(t, e) {
    for (var n = t.lastElementChild; n && (n === Bt.ghost || "none" === P(n, "display") || e && !p(n, e));) n = n.previousElementSibling;

    return n || null;
  }

  function B(t, e) {
    var n = 0;
    if (!t || !t.parentNode) return -1;

    for (; t = t.previousElementSibling;) "TEMPLATE" === t.nodeName.toUpperCase() || t === Bt.clone || e && !p(t, e) || n++;

    return n;
  }

  function E(t) {
    var e = 0,
        n = 0,
        o = O();
    if (t) do {
      var i = v(t),
          r = i.a,
          i = i.d;
    } while ((e += t.scrollLeft * r, n += t.scrollTop * i, t !== o && (t = t.parentNode)));
    return [e, n];
  }

  function A(t, e) {
    if (!t || !t.getBoundingClientRect) return O();
    var n = t,
        o = !1;

    do {
      if (n.clientWidth < n.scrollWidth || n.clientHeight < n.scrollHeight) {
        var i = P(n);

        if (n.clientWidth < n.scrollWidth && ("auto" == i.overflowX || "scroll" == i.overflowX) || n.clientHeight < n.scrollHeight && ("auto" == i.overflowY || "scroll" == i.overflowY)) {
          if (!n.getBoundingClientRect || n === document.body) return O();
          if (o || e) return n;
          o = !0;
        }
      }
    } while (n = n.parentNode);

    return O();
  }

  function D(t, e) {
    return Math.round(t.top) === Math.round(e.top) && Math.round(t.left) === Math.round(e.left) && Math.round(t.height) === Math.round(e.height) && Math.round(t.width) === Math.round(e.width);
  }

  function S(e, n) {
    return function () {
      var t;
      g || (1 === (t = arguments).length ? e.call(this || _global, t[0]) : e.apply(this || _global, t), g = setTimeout(function () {
        g = void 0;
      }, n));
    };
  }

  function F(t, e, n) {
    t.scrollLeft += e, t.scrollTop += n;
  }

  function _(t) {
    var e = window.Polymer,
        n = window.jQuery || window.Zepto;
    return e && e.dom ? e.dom(t).cloneNode(!0) : n ? n(t).clone(!0)[0] : t.cloneNode(!0);
  }

  function C(t, e) {
    P(t, "position", "absolute"), P(t, "top", e.top), P(t, "left", e.left), P(t, "width", e.width), P(t, "height", e.height);
  }

  function T(t) {
    P(t, "position", ""), P(t, "top", ""), P(t, "left", ""), P(t, "width", ""), P(t, "height", "");
  }

  var j = "Sortable" + new Date().getTime();

  function x() {
    var e,
        o = [];
    return {
      captureAnimationState: function () {
        o = [], (this || _global).options.animation && [].slice.call((this || _global).el.children).forEach(function (t) {
          var e, n;
          "none" !== P(t, "display") && t !== Bt.ghost && (o.push({
            target: t,
            rect: k(t)
          }), e = M({}, o[o.length - 1].rect), !t.thisAnimationDuration || (n = v(t, !0)) && (e.top -= n.f, e.left -= n.e), t.fromRect = e);
        });
      },
      addAnimationState: function (t) {
        o.push(t);
      },
      removeAnimationState: function (t) {
        o.splice(function (t, e) {
          for (var n in t) if (t.hasOwnProperty(n)) for (var o in e) if (e.hasOwnProperty(o) && e[o] === t[n][o]) return Number(n);

          return -1;
        }(o, {
          target: t
        }), 1);
      },
      animateAll: function (t) {
        var c = this || _global;
        if (!(this || _global).options.animation) return clearTimeout(e), void ("function" == typeof t && t());
        var u = !1,
            d = 0;
        o.forEach(function (t) {
          var e = 0,
              n = t.target,
              o = n.fromRect,
              i = k(n),
              r = n.prevFromRect,
              a = n.prevToRect,
              l = t.rect,
              s = v(n, !0);
          s && (i.top -= s.f, i.left -= s.e), n.toRect = i, n.thisAnimationDuration && D(r, i) && !D(o, i) && (l.top - i.top) / (l.left - i.left) == (o.top - i.top) / (o.left - i.left) && (t = l, s = r, r = a, a = c.options, e = Math.sqrt(Math.pow(s.top - t.top, 2) + Math.pow(s.left - t.left, 2)) / Math.sqrt(Math.pow(s.top - r.top, 2) + Math.pow(s.left - r.left, 2)) * a.animation), D(i, o) || (n.prevFromRect = o, n.prevToRect = i, e = e || c.options.animation, c.animate(n, l, i, e)), e && (u = !0, d = Math.max(d, e), clearTimeout(n.animationResetTimer), n.animationResetTimer = setTimeout(function () {
            n.animationTime = 0, n.prevFromRect = null, n.fromRect = null, n.prevToRect = null, n.thisAnimationDuration = null;
          }, e), n.thisAnimationDuration = e);
        }), clearTimeout(e), u ? e = setTimeout(function () {
          "function" == typeof t && t();
        }, d) : "function" == typeof t && t(), o = [];
      },
      animate: function (t, e, n, o) {
        var i, r;
        o && (P(t, "transition", ""), P(t, "transform", ""), i = (r = v((this || _global).el)) && r.a, r = r && r.d, i = (e.left - n.left) / (i || 1), r = (e.top - n.top) / (r || 1), t.animatingX = !!i, t.animatingY = !!r, P(t, "transform", "translate3d(" + i + "px," + r + "px,0)"), (this || _global).forRepaintDummy = t.offsetWidth, P(t, "transition", "transform " + o + "ms" + ((this || _global).options.easing ? " " + (this || _global).options.easing : "")), P(t, "transform", "translate3d(0,0,0)"), "number" == typeof t.animated && clearTimeout(t.animated), t.animated = setTimeout(function () {
          P(t, "transition", ""), P(t, "transform", ""), t.animated = !1, t.animatingX = !1, t.animatingY = !1;
        }, o));
      }
    };
  }

  var H = [],
      L = {
    initializeByDefault: !0
  },
      K = {
    mount: function (e) {
      for (var t in L) !L.hasOwnProperty(t) || t in e || (e[t] = L[t]);

      H.forEach(function (t) {
        if (t.pluginName === e.pluginName) throw "Sortable: Cannot mount plugin ".concat(e.pluginName, " more than once");
      }), H.push(e);
    },
    pluginEvent: function (e, n, o) {
      var t = this || _global;
      (this || _global).eventCanceled = !1, o.cancel = function () {
        t.eventCanceled = !0;
      };
      var i = e + "Global";
      H.forEach(function (t) {
        n[t.pluginName] && (n[t.pluginName][i] && n[t.pluginName][i](M({
          sortable: n
        }, o)), n.options[t.pluginName] && n[t.pluginName][e] && n[t.pluginName][e](M({
          sortable: n
        }, o)));
      });
    },
    initializePlugins: function (n, o, i, t) {
      for (var e in H.forEach(function (t) {
        var e = t.pluginName;
        (n.options[e] || t.initializeByDefault) && ((t = new t(n, o, n.options)).sortable = n, t.options = n.options, n[e] = t, a(i, t.defaults));
      }), n.options) {
        var r;
        n.options.hasOwnProperty(e) && void 0 !== (r = this.modifyOption(n, e, n.options[e])) && (n.options[e] = r);
      }
    },
    getEventProperties: function (e, n) {
      var o = {};
      return H.forEach(function (t) {
        "function" == typeof t.eventProperties && a(o, t.eventProperties.call(n[t.pluginName], e));
      }), o;
    },
    modifyOption: function (e, n, o) {
      var i;
      return H.forEach(function (t) {
        e[t.pluginName] && t.optionListeners && "function" == typeof t.optionListeners[n] && (i = t.optionListeners[n].call(e[t.pluginName], o));
      }), i;
    }
  };

  function W(t) {
    var e = t.sortable,
        n = t.rootEl,
        o = t.name,
        i = t.targetEl,
        r = t.cloneEl,
        a = t.toEl,
        l = t.fromEl,
        s = t.oldIndex,
        c = t.newIndex,
        u = t.oldDraggableIndex,
        d = t.newDraggableIndex,
        h = t.originalEvent,
        f = t.putSortable,
        p = t.extraEventProperties;

    if (e = e || n && n[j]) {
      var g,
          m = e.options,
          t = "on" + o.charAt(0).toUpperCase() + o.substr(1);
      !window.CustomEvent || y || w ? (g = document.createEvent("Event")).initEvent(o, !0, !0) : g = new CustomEvent(o, {
        bubbles: !0,
        cancelable: !0
      }), g.to = a || n, g.from = l || n, g.item = i || n, g.clone = r, g.oldIndex = s, g.newIndex = c, g.oldDraggableIndex = u, g.newDraggableIndex = d, g.originalEvent = h, g.pullMode = f ? f.lastPutMode : void 0;
      var v,
          b = M(M({}, p), K.getEventProperties(o, e));

      for (v in b) g[v] = b[v];

      n && n.dispatchEvent(g), m[t] && m[t].call(e, g);
    }
  }

  function z(t, e) {
    var n = (o = 2 < arguments.length && void 0 !== arguments[2] ? arguments[2] : {}).evt,
        o = i(o, G);
    K.pluginEvent.bind(Bt)(t, e, M({
      dragEl: q,
      parentEl: V,
      ghostEl: Z,
      rootEl: $,
      nextEl: Q,
      lastDownEl: J,
      cloneEl: tt,
      cloneHidden: et,
      dragStarted: pt,
      putSortable: lt,
      activeSortable: Bt.active,
      originalEvent: n,
      oldIndex: nt,
      oldDraggableIndex: it,
      newIndex: ot,
      newDraggableIndex: rt,
      hideGhostForTarget: kt,
      unhideGhostForTarget: Rt,
      cloneNowHidden: function () {
        et = !0;
      },
      cloneNowShown: function () {
        et = !1;
      },
      dispatchSortableEvent: function (t) {
        U({
          sortable: e,
          name: t,
          originalEvent: n
        });
      }
    }, o));
  }

  var G = ["evt"];

  function U(t) {
    W(M({
      putSortable: lt,
      cloneEl: tt,
      targetEl: q,
      rootEl: $,
      oldIndex: nt,
      oldDraggableIndex: it,
      newIndex: ot,
      newDraggableIndex: rt
    }, t));
  }

  var q,
      V,
      Z,
      $,
      Q,
      J,
      tt,
      et,
      nt,
      ot,
      it,
      rt,
      at,
      lt,
      st,
      ct,
      ut,
      dt,
      ht,
      ft,
      pt,
      gt,
      mt,
      vt,
      bt,
      yt = !1,
      wt = !1,
      Et = [],
      Dt = !1,
      St = !1,
      _t = [],
      Ct = !1,
      Tt = [],
      xt = "undefined" != typeof document,
      Ot = n,
      At = w || y ? "cssFloat" : "float",
      Mt = xt && !c && !n && "draggable" in document.createElement("div"),
      Nt = function () {
    if (xt) {
      if (y) return !1;
      var t = document.createElement("x");
      return t.style.cssText = "pointer-events:auto", "auto" === t.style.pointerEvents;
    }
  }(),
      It = function (t, e) {
    var n = P(t),
        o = parseInt(n.width) - parseInt(n.paddingLeft) - parseInt(n.paddingRight) - parseInt(n.borderLeftWidth) - parseInt(n.borderRightWidth),
        i = X(t, 0, e),
        r = X(t, 1, e),
        a = i && P(i),
        l = r && P(r),
        s = a && parseInt(a.marginLeft) + parseInt(a.marginRight) + k(i).width,
        t = l && parseInt(l.marginLeft) + parseInt(l.marginRight) + k(r).width;
    if ("flex" === n.display) return "column" === n.flexDirection || "column-reverse" === n.flexDirection ? "vertical" : "horizontal";
    if ("grid" === n.display) return n.gridTemplateColumns.split(" ").length <= 1 ? "vertical" : "horizontal";

    if (i && a.float && "none" !== a.float) {
      e = "left" === a.float ? "left" : "right";
      return !r || "both" !== l.clear && l.clear !== e ? "horizontal" : "vertical";
    }

    return i && ("block" === a.display || "flex" === a.display || "table" === a.display || "grid" === a.display || o <= s && "none" === n[At] || r && "none" === n[At] && o < s + t) ? "vertical" : "horizontal";
  },
      Pt = function (t) {
    function l(r, a) {
      return function (t, e, n, o) {
        var i = t.options.group.name && e.options.group.name && t.options.group.name === e.options.group.name;
        if (null == r && (a || i)) return !0;
        if (null == r || !1 === r) return !1;
        if (a && "clone" === r) return r;
        if ("function" == typeof r) return l(r(t, e, n, o), a)(t, e, n, o);
        e = (a ? t : e).options.group.name;
        return !0 === r || "string" == typeof r && r === e || r.join && -1 < r.indexOf(e);
      };
    }

    var e = {},
        n = t.group;
    n && "object" == o(n) || (n = {
      name: n
    }), e.name = n.name, e.checkPull = l(n.pull, !0), e.checkPut = l(n.put), e.revertClone = n.revertClone, t.group = e;
  },
      kt = function () {
    !Nt && Z && P(Z, "display", "none");
  },
      Rt = function () {
    !Nt && Z && P(Z, "display", "");
  };

  xt && !c && document.addEventListener("click", function (t) {
    if (wt) return t.preventDefault(), t.stopPropagation && t.stopPropagation(), t.stopImmediatePropagation && t.stopImmediatePropagation(), wt = !1;
  }, !0);

  function Xt(t) {
    if (q) {
      t = t.touches ? t.touches[0] : t;
      var e = (i = t.clientX, r = t.clientY, Et.some(function (t) {
        var e = t[j].options.emptyInsertThreshold;

        if (e && !Y(t)) {
          var n = k(t),
              o = i >= n.left - e && i <= n.right + e,
              e = r >= n.top - e && r <= n.bottom + e;
          return o && e ? a = t : void 0;
        }
      }), a);

      if (e) {
        var n,
            o = {};

        for (n in t) t.hasOwnProperty(n) && (o[n] = t[n]);

        o.target = o.rootEl = e, o.preventDefault = void 0, o.stopPropagation = void 0, e[j]._onDragOver(o);
      }
    }

    var i, r, a;
  }

  function Yt(t) {
    q && q.parentNode[j]._isOutsideThisEl(t.target);
  }

  function Bt(t, e) {
    if (!t || !t.nodeType || 1 !== t.nodeType) throw "Sortable: `el` must be an HTMLElement, not ".concat({}.toString.call(t));
    (this || _global).el = t, (this || _global).options = e = a({}, e), t[j] = this || _global;
    var n,
        o,
        i = {
      group: null,
      sort: !0,
      disabled: !1,
      store: null,
      handle: null,
      draggable: /^[uo]l$/i.test(t.nodeName) ? ">li" : ">*",
      swapThreshold: 1,
      invertSwap: !1,
      invertedSwapThreshold: null,
      removeCloneOnHide: !0,
      direction: function () {
        return It(t, (this || _global).options);
      },
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
      ignore: "a, img",
      filter: null,
      preventOnFilter: !0,
      animation: 0,
      easing: null,
      setData: function (t, e) {
        t.setData("Text", e.textContent);
      },
      dropBubble: !1,
      dragoverBubble: !1,
      dataIdAttr: "data-id",
      delay: 0,
      delayOnTouchOnly: !1,
      touchStartThreshold: (Number.parseInt ? Number : window).parseInt(window.devicePixelRatio, 10) || 1,
      forceFallback: !1,
      fallbackClass: "sortable-fallback",
      fallbackOnBody: !1,
      fallbackTolerance: 0,
      fallbackOffset: {
        x: 0,
        y: 0
      },
      supportPointer: !1 !== Bt.supportPointer && "PointerEvent" in window && !u,
      emptyInsertThreshold: 5
    };

    for (n in K.initializePlugins(this || _global, t, i), i) n in e || (e[n] = i[n]);

    for (o in Pt(e), this || _global) "_" === o.charAt(0) && "function" == typeof (this || _global)[o] && ((this || _global)[o] = (this || _global)[o].bind(this || _global));

    (this || _global).nativeDraggable = !e.forceFallback && Mt, (this || _global).nativeDraggable && ((this || _global).options.touchStartThreshold = 1), e.supportPointer ? h(t, "pointerdown", (this || _global)._onTapStart) : (h(t, "mousedown", (this || _global)._onTapStart), h(t, "touchstart", (this || _global)._onTapStart)), (this || _global).nativeDraggable && (h(t, "dragover", this || _global), h(t, "dragenter", this || _global)), Et.push((this || _global).el), e.store && e.store.get && this.sort(e.store.get(this || _global) || []), a(this || _global, x());
  }

  function Ft(t, e, n, o, i, r, a, l) {
    var s,
        c,
        u = t[j],
        d = u.options.onMove;
    return !window.CustomEvent || y || w ? (s = document.createEvent("Event")).initEvent("move", !0, !0) : s = new CustomEvent("move", {
      bubbles: !0,
      cancelable: !0
    }), s.to = e, s.from = t, s.dragged = n, s.draggedRect = o, s.related = i || e, s.relatedRect = r || k(e), s.willInsertAfter = l, s.originalEvent = a, t.dispatchEvent(s), c = d ? d.call(u, s, a) : c;
  }

  function jt(t) {
    t.draggable = !1;
  }

  function Ht() {
    Ct = !1;
  }

  function Lt(t) {
    return setTimeout(t, 0);
  }

  function Kt(t) {
    return clearTimeout(t);
  }

  Bt.prototype = {
    constructor: Bt,
    _isOutsideThisEl: function (t) {
      (this || _global).el.contains(t) || t === (this || _global).el || (gt = null);
    },
    _getDirection: function (t, e) {
      return "function" == typeof (this || _global).options.direction ? (this || _global).options.direction.call(this || _global, t, e, q) : (this || _global).options.direction;
    },
    _onTapStart: function (e) {
      if (e.cancelable) {
        var n = this || _global,
            o = (this || _global).el,
            t = (this || _global).options,
            i = t.preventOnFilter,
            r = e.type,
            a = e.touches && e.touches[0] || e.pointerType && "touch" === e.pointerType && e,
            l = (a || e).target,
            s = e.target.shadowRoot && (e.path && e.path[0] || e.composedPath && e.composedPath()[0]) || l,
            c = t.filter;

        if (!function (t) {
          Tt.length = 0;
          var e = t.getElementsByTagName("input"),
              n = e.length;

          for (; n--;) {
            var o = e[n];
            o.checked && Tt.push(o);
          }
        }(o), !q && !(/mousedown|pointerdown/.test(r) && 0 !== e.button || t.disabled) && !s.isContentEditable && ((this || _global).nativeDraggable || !u || !l || "SELECT" !== l.tagName.toUpperCase()) && !((l = N(l, t.draggable, o, !1)) && l.animated || J === l)) {
          if (nt = B(l), it = B(l, t.draggable), "function" == typeof c) {
            if (c.call(this || _global, e, l, this || _global)) return U({
              sortable: n,
              rootEl: s,
              name: "filter",
              targetEl: l,
              toEl: o,
              fromEl: o
            }), z("filter", n, {
              evt: e
            }), void (i && e.cancelable && e.preventDefault());
          } else if (c = c && c.split(",").some(function (t) {
            if (t = N(s, t.trim(), o, !1)) return U({
              sortable: n,
              rootEl: t,
              name: "filter",
              targetEl: l,
              fromEl: o,
              toEl: o
            }), z("filter", n, {
              evt: e
            }), !0;
          })) return void (i && e.cancelable && e.preventDefault());

          t.handle && !N(s, t.handle, o, !1) || this._prepareDragStart(e, a, l);
        }
      }
    },
    _prepareDragStart: function (t, e, n) {
      var o,
          i = this || _global,
          r = i.el,
          a = i.options,
          l = r.ownerDocument;
      n && !q && n.parentNode === r && (o = k(n), $ = r, V = (q = n).parentNode, Q = q.nextSibling, J = n, at = a.group, st = {
        target: Bt.dragged = q,
        clientX: (e || t).clientX,
        clientY: (e || t).clientY
      }, ht = st.clientX - o.left, ft = st.clientY - o.top, (this || _global)._lastX = (e || t).clientX, (this || _global)._lastY = (e || t).clientY, q.style["will-change"] = "all", o = function () {
        z("delayEnded", i, {
          evt: t
        }), Bt.eventCanceled ? i._onDrop() : (i._disableDelayedDragEvents(), !s && i.nativeDraggable && (q.draggable = !0), i._triggerDragStart(t, e), U({
          sortable: i,
          name: "choose",
          originalEvent: t
        }), I(q, a.chosenClass, !0));
      }, a.ignore.split(",").forEach(function (t) {
        b(q, t.trim(), jt);
      }), h(l, "dragover", Xt), h(l, "mousemove", Xt), h(l, "touchmove", Xt), h(l, "mouseup", i._onDrop), h(l, "touchend", i._onDrop), h(l, "touchcancel", i._onDrop), s && (this || _global).nativeDraggable && ((this || _global).options.touchStartThreshold = 4, q.draggable = !0), z("delayStart", this || _global, {
        evt: t
      }), !a.delay || a.delayOnTouchOnly && !e || (this || _global).nativeDraggable && (w || y) ? o() : Bt.eventCanceled ? this._onDrop() : (h(l, "mouseup", i._disableDelayedDrag), h(l, "touchend", i._disableDelayedDrag), h(l, "touchcancel", i._disableDelayedDrag), h(l, "mousemove", i._delayedDragTouchMoveHandler), h(l, "touchmove", i._delayedDragTouchMoveHandler), a.supportPointer && h(l, "pointermove", i._delayedDragTouchMoveHandler), i._dragStartTimer = setTimeout(o, a.delay)));
    },
    _delayedDragTouchMoveHandler: function (t) {
      t = t.touches ? t.touches[0] : t;
      Math.max(Math.abs(t.clientX - (this || _global)._lastX), Math.abs(t.clientY - (this || _global)._lastY)) >= Math.floor((this || _global).options.touchStartThreshold / ((this || _global).nativeDraggable && window.devicePixelRatio || 1)) && this._disableDelayedDrag();
    },
    _disableDelayedDrag: function () {
      q && jt(q), clearTimeout((this || _global)._dragStartTimer), this._disableDelayedDragEvents();
    },
    _disableDelayedDragEvents: function () {
      var t = (this || _global).el.ownerDocument;
      f(t, "mouseup", (this || _global)._disableDelayedDrag), f(t, "touchend", (this || _global)._disableDelayedDrag), f(t, "touchcancel", (this || _global)._disableDelayedDrag), f(t, "mousemove", (this || _global)._delayedDragTouchMoveHandler), f(t, "touchmove", (this || _global)._delayedDragTouchMoveHandler), f(t, "pointermove", (this || _global)._delayedDragTouchMoveHandler);
    },
    _triggerDragStart: function (t, e) {
      e = e || "touch" == t.pointerType && t, !(this || _global).nativeDraggable || e ? (this || _global).options.supportPointer ? h(document, "pointermove", (this || _global)._onTouchMove) : h(document, e ? "touchmove" : "mousemove", (this || _global)._onTouchMove) : (h(q, "dragend", this || _global), h($, "dragstart", (this || _global)._onDragStart));

      try {
        document.selection ? Lt(function () {
          document.selection.empty();
        }) : window.getSelection().removeAllRanges();
      } catch (t) {}
    },
    _dragStarted: function (t, e) {
      var n;
      yt = !1, $ && q ? (z("dragStarted", this || _global, {
        evt: e
      }), (this || _global).nativeDraggable && h(document, "dragover", Yt), n = (this || _global).options, t || I(q, n.dragClass, !1), I(q, n.ghostClass, !0), Bt.active = this || _global, t && this._appendGhost(), U({
        sortable: this || _global,
        name: "start",
        originalEvent: e
      })) : this._nulling();
    },
    _emulateDragOver: function () {
      if (ct) {
        (this || _global)._lastX = ct.clientX, (this || _global)._lastY = ct.clientY, kt();

        for (var t = document.elementFromPoint(ct.clientX, ct.clientY), e = t; t && t.shadowRoot && (t = t.shadowRoot.elementFromPoint(ct.clientX, ct.clientY)) !== e;) e = t;

        if (q.parentNode[j]._isOutsideThisEl(t), e) do {
          if (e[j]) if (e[j]._onDragOver({
            clientX: ct.clientX,
            clientY: ct.clientY,
            target: t,
            rootEl: e
          }) && !(this || _global).options.dragoverBubble) break;
        } while (e = (t = e).parentNode);
        Rt();
      }
    },
    _onTouchMove: function (t) {
      if (st) {
        var e = (this || _global).options,
            n = e.fallbackTolerance,
            o = e.fallbackOffset,
            i = t.touches ? t.touches[0] : t,
            r = Z && v(Z, !0),
            a = Z && r && r.a,
            l = Z && r && r.d,
            e = Ot && bt && E(bt),
            a = (i.clientX - st.clientX + o.x) / (a || 1) + (e ? e[0] - _t[0] : 0) / (a || 1),
            l = (i.clientY - st.clientY + o.y) / (l || 1) + (e ? e[1] - _t[1] : 0) / (l || 1);

        if (!Bt.active && !yt) {
          if (n && Math.max(Math.abs(i.clientX - (this || _global)._lastX), Math.abs(i.clientY - (this || _global)._lastY)) < n) return;

          this._onDragStart(t, !0);
        }

        Z && (r ? (r.e += a - (ut || 0), r.f += l - (dt || 0)) : r = {
          a: 1,
          b: 0,
          c: 0,
          d: 1,
          e: a,
          f: l
        }, r = "matrix(".concat(r.a, ",").concat(r.b, ",").concat(r.c, ",").concat(r.d, ",").concat(r.e, ",").concat(r.f, ")"), P(Z, "webkitTransform", r), P(Z, "mozTransform", r), P(Z, "msTransform", r), P(Z, "transform", r), ut = a, dt = l, ct = i), t.cancelable && t.preventDefault();
      }
    },
    _appendGhost: function () {
      if (!Z) {
        var t = (this || _global).options.fallbackOnBody ? document.body : $,
            e = k(q, !0, Ot, !0, t),
            n = (this || _global).options;

        if (Ot) {
          for (bt = t; "static" === P(bt, "position") && "none" === P(bt, "transform") && bt !== document;) bt = bt.parentNode;

          bt !== document.body && bt !== document.documentElement ? (bt === document && (bt = O()), e.top += bt.scrollTop, e.left += bt.scrollLeft) : bt = O(), _t = E(bt);
        }

        I(Z = q.cloneNode(!0), n.ghostClass, !1), I(Z, n.fallbackClass, !0), I(Z, n.dragClass, !0), P(Z, "transition", ""), P(Z, "transform", ""), P(Z, "box-sizing", "border-box"), P(Z, "margin", 0), P(Z, "top", e.top), P(Z, "left", e.left), P(Z, "width", e.width), P(Z, "height", e.height), P(Z, "opacity", "0.8"), P(Z, "position", Ot ? "absolute" : "fixed"), P(Z, "zIndex", "100000"), P(Z, "pointerEvents", "none"), Bt.ghost = Z, t.appendChild(Z), P(Z, "transform-origin", ht / parseInt(Z.style.width) * 100 + "% " + ft / parseInt(Z.style.height) * 100 + "%");
      }
    },
    _onDragStart: function (t, e) {
      var n = this || _global,
          o = t.dataTransfer,
          i = n.options;
      z("dragStart", this || _global, {
        evt: t
      }), Bt.eventCanceled ? this._onDrop() : (z("setupClone", this || _global), Bt.eventCanceled || ((tt = _(q)).removeAttribute("id"), tt.draggable = !1, tt.style["will-change"] = "", this._hideClone(), I(tt, (this || _global).options.chosenClass, !1), Bt.clone = tt), n.cloneId = Lt(function () {
        z("clone", n), Bt.eventCanceled || (n.options.removeCloneOnHide || $.insertBefore(tt, q), n._hideClone(), U({
          sortable: n,
          name: "clone"
        }));
      }), e || I(q, i.dragClass, !0), e ? (wt = !0, n._loopId = setInterval(n._emulateDragOver, 50)) : (f(document, "mouseup", n._onDrop), f(document, "touchend", n._onDrop), f(document, "touchcancel", n._onDrop), o && (o.effectAllowed = "move", i.setData && i.setData.call(n, o, q)), h(document, "drop", n), P(q, "transform", "translateZ(0)")), yt = !0, n._dragStartId = Lt(n._dragStarted.bind(n, e, t)), h(document, "selectstart", n), pt = !0, u && P(document.body, "user-select", "none"));
    },
    _onDragOver: function (n) {
      var o,
          i,
          r,
          t,
          a = (this || _global).el,
          l = n.target,
          e = (this || _global).options,
          s = e.group,
          c = Bt.active,
          u = at === s,
          d = e.sort,
          h = lt || c,
          f = this || _global,
          p = !1;

      if (!Ct) {
        if (void 0 !== n.preventDefault && n.cancelable && n.preventDefault(), l = N(l, e.draggable, a, !0), T("dragOver"), Bt.eventCanceled) return p;
        if (q.contains(n.target) || l.animated && l.animatingX && l.animatingY || f._ignoreWhileAnimating === l) return O(!1);

        if (wt = !1, c && !e.disabled && (u ? d || (i = V !== $) : lt === (this || _global) || ((this || _global).lastPutMode = at.checkPull(this || _global, c, q, n)) && s.checkPut(this || _global, c, q, n))) {
          if (r = "vertical" === this._getDirection(n, l), o = k(q), T("dragOverValid"), Bt.eventCanceled) return p;
          if (i) return V = $, x(), this._hideClone(), T("revert"), Bt.eventCanceled || (Q ? $.insertBefore(q, Q) : $.appendChild(q)), O(!0);
          var g = Y(a, e.draggable);

          if (!g || function (t, e, n) {
            n = k(Y(n.el, n.options.draggable));
            return e ? t.clientX > n.right + 10 || t.clientX <= n.right && t.clientY > n.bottom && t.clientX >= n.left : t.clientX > n.right && t.clientY > n.top || t.clientX <= n.right && t.clientY > n.bottom + 10;
          }(n, r, this || _global) && !g.animated) {
            if (g === q) return O(!1);
            if ((l = g && a === n.target ? g : l) && (w = k(l)), !1 !== Ft($, a, q, o, l, w, n, !!l)) return x(), g && g.nextSibling ? a.insertBefore(q, g.nextSibling) : a.appendChild(q), V = a, A(), O(!0);
          } else if (g && function (t, e, n) {
            n = k(X(n.el, 0, n.options, !0));
            return e ? t.clientX < n.left - 10 || t.clientY < n.top && t.clientX < n.right : t.clientY < n.top - 10 || t.clientY < n.bottom && t.clientX < n.left;
          }(n, r, this || _global)) {
            var m = X(a, 0, e, !0);
            if (m === q) return O(!1);
            if (w = k(l = m), !1 !== Ft($, a, q, o, l, w, n, !1)) return x(), a.insertBefore(q, m), V = a, A(), O(!0);
          } else if (l.parentNode === a) {
            var v,
                b,
                y,
                w = k(l),
                E = q.parentNode !== a,
                D = (D = q.animated && q.toRect || o, C = l.animated && l.toRect || w, S = (t = r) ? D.left : D.top, s = t ? D.right : D.bottom, g = t ? D.width : D.height, m = t ? C.left : C.top, D = t ? C.right : C.bottom, C = t ? C.width : C.height, !(S === m || s === D || S + g / 2 === m + C / 2)),
                S = r ? "top" : "left",
                g = R(l, "top", "top") || R(q, "top", "top"),
                m = g ? g.scrollTop : void 0;
            if (gt !== l && (b = w[S], Dt = !1, St = !D && e.invertSwap || E), 0 !== (v = function (t, e, n, o, i, r, a, l) {
              var s = o ? t.clientY : t.clientX,
                  c = o ? n.height : n.width,
                  t = o ? n.top : n.left,
                  o = o ? n.bottom : n.right,
                  n = !1;
              if (!a) if (l && vt < c * i) {
                if (Dt = !Dt && (1 === mt ? t + c * r / 2 < s : s < o - c * r / 2) ? !0 : Dt) n = !0;else if (1 === mt ? s < t + vt : o - vt < s) return -mt;
              } else if (t + c * (1 - i) / 2 < s && s < o - c * (1 - i) / 2) return function (t) {
                return B(q) < B(t) ? 1 : -1;
              }(e);
              if ((n = n || a) && (s < t + c * r / 2 || o - c * r / 2 < s)) return t + c / 2 < s ? 1 : -1;
              return 0;
            }(n, l, w, r, D ? 1 : e.swapThreshold, null == e.invertedSwapThreshold ? e.swapThreshold : e.invertedSwapThreshold, St, gt === l))) for (var _ = B(q); (y = V.children[_ -= v]) && ("none" === P(y, "display") || y === Z););
            if (0 === v || y === l) return O(!1);
            mt = v;
            var C = (gt = l).nextElementSibling,
                E = !1,
                D = Ft($, a, q, o, l, w, n, E = 1 === v);
            if (!1 !== D) return 1 !== D && -1 !== D || (E = 1 === D), Ct = !0, setTimeout(Ht, 30), x(), E && !C ? a.appendChild(q) : l.parentNode.insertBefore(q, E ? C : l), g && F(g, 0, m - g.scrollTop), V = q.parentNode, void 0 === b || St || (vt = Math.abs(b - k(l)[S])), A(), O(!0);
          }

          if (a.contains(q)) return O(!1);
        }

        return !1;
      }

      function T(t, e) {
        z(t, f, M({
          evt: n,
          isOwner: u,
          axis: r ? "vertical" : "horizontal",
          revert: i,
          dragRect: o,
          targetRect: w,
          canSort: d,
          fromSortable: h,
          target: l,
          completed: O,
          onMove: function (t, e) {
            return Ft($, a, q, o, t, k(t), n, e);
          },
          changed: A
        }, e));
      }

      function x() {
        T("dragOverAnimationCapture"), f.captureAnimationState(), f !== h && h.captureAnimationState();
      }

      function O(t) {
        return T("dragOverCompleted", {
          insertion: t
        }), t && (u ? c._hideClone() : c._showClone(f), f !== h && (I(q, (lt || c).options.ghostClass, !1), I(q, e.ghostClass, !0)), lt !== f && f !== Bt.active ? lt = f : f === Bt.active && lt && (lt = null), h === f && (f._ignoreWhileAnimating = l), f.animateAll(function () {
          T("dragOverAnimationComplete"), f._ignoreWhileAnimating = null;
        }), f !== h && (h.animateAll(), h._ignoreWhileAnimating = null)), (l === q && !q.animated || l === a && !l.animated) && (gt = null), e.dragoverBubble || n.rootEl || l === document || (q.parentNode[j]._isOutsideThisEl(n.target), t || Xt(n)), !e.dragoverBubble && n.stopPropagation && n.stopPropagation(), p = !0;
      }

      function A() {
        ot = B(q), rt = B(q, e.draggable), U({
          sortable: f,
          name: "change",
          toEl: a,
          newIndex: ot,
          newDraggableIndex: rt,
          originalEvent: n
        });
      }
    },
    _ignoreWhileAnimating: null,
    _offMoveEvents: function () {
      f(document, "mousemove", (this || _global)._onTouchMove), f(document, "touchmove", (this || _global)._onTouchMove), f(document, "pointermove", (this || _global)._onTouchMove), f(document, "dragover", Xt), f(document, "mousemove", Xt), f(document, "touchmove", Xt);
    },
    _offUpEvents: function () {
      var t = (this || _global).el.ownerDocument;
      f(t, "mouseup", (this || _global)._onDrop), f(t, "touchend", (this || _global)._onDrop), f(t, "pointerup", (this || _global)._onDrop), f(t, "touchcancel", (this || _global)._onDrop), f(document, "selectstart", this || _global);
    },
    _onDrop: function (t) {
      var e = (this || _global).el,
          n = (this || _global).options;
      ot = B(q), rt = B(q, n.draggable), z("drop", this || _global, {
        evt: t
      }), V = q && q.parentNode, ot = B(q), rt = B(q, n.draggable), Bt.eventCanceled || (Dt = St = yt = !1, clearInterval((this || _global)._loopId), clearTimeout((this || _global)._dragStartTimer), Kt((this || _global).cloneId), Kt((this || _global)._dragStartId), (this || _global).nativeDraggable && (f(document, "drop", this || _global), f(e, "dragstart", (this || _global)._onDragStart)), this._offMoveEvents(), this._offUpEvents(), u && P(document.body, "user-select", ""), P(q, "transform", ""), t && (pt && (t.cancelable && t.preventDefault(), n.dropBubble || t.stopPropagation()), Z && Z.parentNode && Z.parentNode.removeChild(Z), ($ === V || lt && "clone" !== lt.lastPutMode) && tt && tt.parentNode && tt.parentNode.removeChild(tt), q && ((this || _global).nativeDraggable && f(q, "dragend", this || _global), jt(q), q.style["will-change"] = "", pt && !yt && I(q, (lt || this || _global).options.ghostClass, !1), I(q, (this || _global).options.chosenClass, !1), U({
        sortable: this || _global,
        name: "unchoose",
        toEl: V,
        newIndex: null,
        newDraggableIndex: null,
        originalEvent: t
      }), $ !== V ? (0 <= ot && (U({
        rootEl: V,
        name: "add",
        toEl: V,
        fromEl: $,
        originalEvent: t
      }), U({
        sortable: this || _global,
        name: "remove",
        toEl: V,
        originalEvent: t
      }), U({
        rootEl: V,
        name: "sort",
        toEl: V,
        fromEl: $,
        originalEvent: t
      }), U({
        sortable: this || _global,
        name: "sort",
        toEl: V,
        originalEvent: t
      })), lt && lt.save()) : ot !== nt && 0 <= ot && (U({
        sortable: this || _global,
        name: "update",
        toEl: V,
        originalEvent: t
      }), U({
        sortable: this || _global,
        name: "sort",
        toEl: V,
        originalEvent: t
      })), Bt.active && (null != ot && -1 !== ot || (ot = nt, rt = it), U({
        sortable: this || _global,
        name: "end",
        toEl: V,
        originalEvent: t
      }), this.save())))), this._nulling();
    },
    _nulling: function () {
      z("nulling", this || _global), $ = q = V = Z = Q = tt = J = et = st = ct = pt = ot = rt = nt = it = gt = mt = lt = at = Bt.dragged = Bt.ghost = Bt.clone = Bt.active = null, Tt.forEach(function (t) {
        t.checked = !0;
      }), Tt.length = ut = dt = 0;
    },
    handleEvent: function (t) {
      switch (t.type) {
        case "drop":
        case "dragend":
          this._onDrop(t);

          break;

        case "dragenter":
        case "dragover":
          q && (this._onDragOver(t), function (t) {
            t.dataTransfer && (t.dataTransfer.dropEffect = "move");
            t.cancelable && t.preventDefault();
          }(t));
          break;

        case "selectstart":
          t.preventDefault();
      }
    },
    toArray: function () {
      for (var t, e = [], n = (this || _global).el.children, o = 0, i = n.length, r = (this || _global).options; o < i; o++) N(t = n[o], r.draggable, (this || _global).el, !1) && e.push(t.getAttribute(r.dataIdAttr) || function (t) {
        var e = t.tagName + t.className + t.src + t.href + t.textContent,
            n = e.length,
            o = 0;

        for (; n--;) o += e.charCodeAt(n);

        return o.toString(36);
      }(t));

      return e;
    },
    sort: function (t, e) {
      var n = {},
          o = (this || _global).el;
      this.toArray().forEach(function (t, e) {
        e = o.children[e];
        N(e, (this || _global).options.draggable, o, !1) && (n[t] = e);
      }, this || _global), e && this.captureAnimationState(), t.forEach(function (t) {
        n[t] && (o.removeChild(n[t]), o.appendChild(n[t]));
      }), e && this.animateAll();
    },
    save: function () {
      var t = (this || _global).options.store;
      t && t.set && t.set(this || _global);
    },
    closest: function (t, e) {
      return N(t, e || (this || _global).options.draggable, (this || _global).el, !1);
    },
    option: function (t, e) {
      var n = (this || _global).options;
      if (void 0 === e) return n[t];
      var o = K.modifyOption(this || _global, t, e);
      n[t] = void 0 !== o ? o : e, "group" === t && Pt(n);
    },
    destroy: function () {
      z("destroy", this || _global);
      var t = (this || _global).el;
      t[j] = null, f(t, "mousedown", (this || _global)._onTapStart), f(t, "touchstart", (this || _global)._onTapStart), f(t, "pointerdown", (this || _global)._onTapStart), (this || _global).nativeDraggable && (f(t, "dragover", this || _global), f(t, "dragenter", this || _global)), Array.prototype.forEach.call(t.querySelectorAll("[draggable]"), function (t) {
        t.removeAttribute("draggable");
      }), this._onDrop(), this._disableDelayedDragEvents(), Et.splice(Et.indexOf((this || _global).el), 1), (this || _global).el = t = null;
    },
    _hideClone: function () {
      et || (z("hideClone", this || _global), Bt.eventCanceled || (P(tt, "display", "none"), (this || _global).options.removeCloneOnHide && tt.parentNode && tt.parentNode.removeChild(tt), et = !0));
    },
    _showClone: function (t) {
      "clone" === t.lastPutMode ? et && (z("showClone", this || _global), Bt.eventCanceled || (q.parentNode != $ || (this || _global).options.group.revertClone ? Q ? $.insertBefore(tt, Q) : $.appendChild(tt) : $.insertBefore(tt, q), (this || _global).options.group.revertClone && this.animate(q, tt), P(tt, "display", ""), et = !1)) : this._hideClone();
    }
  }, xt && h(document, "touchmove", function (t) {
    (Bt.active || yt) && t.cancelable && t.preventDefault();
  }), Bt.utils = {
    on: h,
    off: f,
    css: P,
    find: b,
    is: function (t, e) {
      return !!N(t, e, t, !1);
    },
    extend: function (t, e) {
      if (t && e) for (var n in e) e.hasOwnProperty(n) && (t[n] = e[n]);
      return t;
    },
    throttle: S,
    closest: N,
    toggleClass: I,
    clone: _,
    index: B,
    nextTick: Lt,
    cancelNextTick: Kt,
    detectDirection: It,
    getChild: X
  }, Bt.get = function (t) {
    return t[j];
  }, Bt.mount = function () {
    for (var t = arguments.length, e = new Array(t), n = 0; n < t; n++) e[n] = arguments[n];

    (e = e[0].constructor === Array ? e[0] : e).forEach(function (t) {
      if (!t.prototype || !t.prototype.constructor) throw "Sortable: Mounted plugin must be a constructor function, not ".concat({}.toString.call(t));
      t.utils && (Bt.utils = M(M({}, Bt.utils), t.utils)), K.mount(t);
    });
  }, Bt.create = function (t, e) {
    return new Bt(t, e);
  };
  var Wt,
      zt,
      Gt,
      Ut,
      qt,
      Vt,
      Zt = [],
      $t = !(Bt.version = "1.15.0");

  function Qt() {
    Zt.forEach(function (t) {
      clearInterval(t.pid);
    }), Zt = [];
  }

  function Jt() {
    clearInterval(Vt);
  }

  var te,
      ee = S(function (n, t, e, o) {
    if (t.scroll) {
      var i,
          r = (n.touches ? n.touches[0] : n).clientX,
          a = (n.touches ? n.touches[0] : n).clientY,
          l = t.scrollSensitivity,
          s = t.scrollSpeed,
          c = O(),
          u = !1;
      zt !== e && (zt = e, Qt(), Wt = t.scroll, i = t.scrollFn, !0 === Wt && (Wt = A(e, !0)));
      var d = 0,
          h = Wt;

      do {
        var f = h,
            p = k(f),
            g = p.top,
            m = p.bottom,
            v = p.left,
            b = p.right,
            y = p.width,
            w = p.height,
            E = void 0,
            D = void 0,
            S = f.scrollWidth,
            _ = f.scrollHeight,
            C = P(f),
            T = f.scrollLeft,
            p = f.scrollTop,
            D = f === c ? (E = y < S && ("auto" === C.overflowX || "scroll" === C.overflowX || "visible" === C.overflowX), w < _ && ("auto" === C.overflowY || "scroll" === C.overflowY || "visible" === C.overflowY)) : (E = y < S && ("auto" === C.overflowX || "scroll" === C.overflowX), w < _ && ("auto" === C.overflowY || "scroll" === C.overflowY)),
            T = E && (Math.abs(b - r) <= l && T + y < S) - (Math.abs(v - r) <= l && !!T),
            p = D && (Math.abs(m - a) <= l && p + w < _) - (Math.abs(g - a) <= l && !!p);
        if (!Zt[d]) for (var x = 0; x <= d; x++) Zt[x] || (Zt[x] = {});
        Zt[d].vx == T && Zt[d].vy == p && Zt[d].el === f || (Zt[d].el = f, Zt[d].vx = T, Zt[d].vy = p, clearInterval(Zt[d].pid), 0 == T && 0 == p || (u = !0, Zt[d].pid = setInterval(function () {
          o && 0 === (this || _global).layer && Bt.active._onTouchMove(qt);
          var t = Zt[(this || _global).layer].vy ? Zt[(this || _global).layer].vy * s : 0,
              e = Zt[(this || _global).layer].vx ? Zt[(this || _global).layer].vx * s : 0;
          "function" == typeof i && "continue" !== i.call(Bt.dragged.parentNode[j], e, t, n, qt, Zt[(this || _global).layer].el) || F(Zt[(this || _global).layer].el, e, t);
        }.bind({
          layer: d
        }), 24))), d++;
      } while (t.bubbleScroll && h !== c && (h = A(h, !1)));

      $t = u;
    }
  }, 30),
      c = function (t) {
    var e = t.originalEvent,
        n = t.putSortable,
        o = t.dragEl,
        i = t.activeSortable,
        r = t.dispatchSortableEvent,
        a = t.hideGhostForTarget,
        t = t.unhideGhostForTarget;
    e && (i = n || i, a(), e = e.changedTouches && e.changedTouches.length ? e.changedTouches[0] : e, e = document.elementFromPoint(e.clientX, e.clientY), t(), i && !i.el.contains(e) && (r("spill"), this.onSpill({
      dragEl: o,
      putSortable: n
    })));
  };

  function ne() {}

  function oe() {}

  ne.prototype = {
    startIndex: null,
    dragStart: function (t) {
      t = t.oldDraggableIndex;
      (this || _global).startIndex = t;
    },
    onSpill: function (t) {
      var e = t.dragEl,
          n = t.putSortable;
      (this || _global).sortable.captureAnimationState(), n && n.captureAnimationState();
      t = X((this || _global).sortable.el, (this || _global).startIndex, (this || _global).options);
      t ? (this || _global).sortable.el.insertBefore(e, t) : (this || _global).sortable.el.appendChild(e), (this || _global).sortable.animateAll(), n && n.animateAll();
    },
    drop: c
  }, a(ne, {
    pluginName: "revertOnSpill"
  }), oe.prototype = {
    onSpill: function (t) {
      var e = t.dragEl,
          t = t.putSortable || (this || _global).sortable;
      t.captureAnimationState(), e.parentNode && e.parentNode.removeChild(e), t.animateAll();
    },
    drop: c
  }, a(oe, {
    pluginName: "removeOnSpill"
  });
  var ie,
      re,
      ae,
      le,
      se,
      ce = [],
      ue = [],
      de = !1,
      he = !1,
      fe = !1;

  function pe(n, o) {
    ue.forEach(function (t, e) {
      e = o.children[t.sortableIndex + (n ? Number(e) : 0)];
      e ? o.insertBefore(t, e) : o.appendChild(t);
    });
  }

  function ge() {
    ce.forEach(function (t) {
      t !== ae && t.parentNode && t.parentNode.removeChild(t);
    });
  }

  return Bt.mount(new function () {
    function t() {
      for (var t in (this || _global).defaults = {
        scroll: !0,
        forceAutoScrollFallback: !1,
        scrollSensitivity: 30,
        scrollSpeed: 10,
        bubbleScroll: !0
      }, this || _global) "_" === t.charAt(0) && "function" == typeof (this || _global)[t] && ((this || _global)[t] = (this || _global)[t].bind(this || _global));
    }

    return t.prototype = {
      dragStarted: function (t) {
        t = t.originalEvent;
        (this || _global).sortable.nativeDraggable ? h(document, "dragover", (this || _global)._handleAutoScroll) : (this || _global).options.supportPointer ? h(document, "pointermove", (this || _global)._handleFallbackAutoScroll) : t.touches ? h(document, "touchmove", (this || _global)._handleFallbackAutoScroll) : h(document, "mousemove", (this || _global)._handleFallbackAutoScroll);
      },
      dragOverCompleted: function (t) {
        t = t.originalEvent;
        (this || _global).options.dragOverBubble || t.rootEl || this._handleAutoScroll(t);
      },
      drop: function () {
        (this || _global).sortable.nativeDraggable ? f(document, "dragover", (this || _global)._handleAutoScroll) : (f(document, "pointermove", (this || _global)._handleFallbackAutoScroll), f(document, "touchmove", (this || _global)._handleFallbackAutoScroll), f(document, "mousemove", (this || _global)._handleFallbackAutoScroll)), Jt(), Qt(), clearTimeout(g), g = void 0;
      },
      nulling: function () {
        qt = zt = Wt = $t = Vt = Gt = Ut = null, Zt.length = 0;
      },
      _handleFallbackAutoScroll: function (t) {
        this._handleAutoScroll(t, !0);
      },
      _handleAutoScroll: function (e, n) {
        var o,
            i = this || _global,
            r = (e.touches ? e.touches[0] : e).clientX,
            a = (e.touches ? e.touches[0] : e).clientY,
            t = document.elementFromPoint(r, a);
        qt = e, n || (this || _global).options.forceAutoScrollFallback || w || y || u ? (ee(e, (this || _global).options, t, n), o = A(t, !0), !$t || Vt && r === Gt && a === Ut || (Vt && Jt(), Vt = setInterval(function () {
          var t = A(document.elementFromPoint(r, a), !0);
          t !== o && (o = t, Qt()), ee(e, i.options, t, n);
        }, 10), Gt = r, Ut = a)) : (this || _global).options.bubbleScroll && A(t, !0) !== O() ? ee(e, (this || _global).options, A(t, !1), !1) : Qt();
      }
    }, a(t, {
      pluginName: "scroll",
      initializeByDefault: !0
    });
  }()), Bt.mount(oe, ne), Bt.mount(new function () {
    function t() {
      (this || _global).defaults = {
        swapClass: "sortable-swap-highlight"
      };
    }

    return t.prototype = {
      dragStart: function (t) {
        t = t.dragEl;
        te = t;
      },
      dragOverValid: function (t) {
        var e = t.completed,
            n = t.target,
            o = t.onMove,
            i = t.activeSortable,
            r = t.changed,
            a = t.cancel;
        i.options.swap && (t = (this || _global).sortable.el, i = (this || _global).options, n && n !== t && (t = te, te = !1 !== o(n) ? (I(n, i.swapClass, !0), n) : null, t && t !== te && I(t, i.swapClass, !1)), r(), e(!0), a());
      },
      drop: function (t) {
        var e,
            n,
            o = t.activeSortable,
            i = t.putSortable,
            r = t.dragEl,
            a = i || (this || _global).sortable,
            l = (this || _global).options;
        te && I(te, l.swapClass, !1), te && (l.swap || i && i.options.swap) && r !== te && (a.captureAnimationState(), a !== o && o.captureAnimationState(), n = te, t = (e = r).parentNode, l = n.parentNode, t && l && !t.isEqualNode(n) && !l.isEqualNode(e) && (i = B(e), r = B(n), t.isEqualNode(l) && i < r && r++, t.insertBefore(n, t.children[i]), l.insertBefore(e, l.children[r])), a.animateAll(), a !== o && o.animateAll());
      },
      nulling: function () {
        te = null;
      }
    }, a(t, {
      pluginName: "swap",
      eventProperties: function () {
        return {
          swapItem: te
        };
      }
    });
  }()), Bt.mount(new function () {
    function t(o) {
      for (var t in this || _global) "_" === t.charAt(0) && "function" == typeof (this || _global)[t] && ((this || _global)[t] = (this || _global)[t].bind(this || _global));

      o.options.avoidImplicitDeselect || (o.options.supportPointer ? h(document, "pointerup", (this || _global)._deselectMultiDrag) : (h(document, "mouseup", (this || _global)._deselectMultiDrag), h(document, "touchend", (this || _global)._deselectMultiDrag))), h(document, "keydown", (this || _global)._checkKeyDown), h(document, "keyup", (this || _global)._checkKeyUp), (this || _global).defaults = {
        selectedClass: "sortable-selected",
        multiDragKey: null,
        avoidImplicitDeselect: !1,
        setData: function (t, e) {
          var n = "";
          ce.length && re === o ? ce.forEach(function (t, e) {
            n += (e ? ", " : "") + t.textContent;
          }) : n = e.textContent, t.setData("Text", n);
        }
      };
    }

    return t.prototype = {
      multiDragKeyDown: !1,
      isMultiDrag: !1,
      delayStartGlobal: function (t) {
        t = t.dragEl;
        ae = t;
      },
      delayEnded: function () {
        (this || _global).isMultiDrag = ~ce.indexOf(ae);
      },
      setupClone: function (t) {
        var e = t.sortable,
            t = t.cancel;

        if ((this || _global).isMultiDrag) {
          for (var n = 0; n < ce.length; n++) ue.push(_(ce[n])), ue[n].sortableIndex = ce[n].sortableIndex, ue[n].draggable = !1, ue[n].style["will-change"] = "", I(ue[n], (this || _global).options.selectedClass, !1), ce[n] === ae && I(ue[n], (this || _global).options.chosenClass, !1);

          e._hideClone(), t();
        }
      },
      clone: function (t) {
        var e = t.sortable,
            n = t.rootEl,
            o = t.dispatchSortableEvent,
            t = t.cancel;
        (this || _global).isMultiDrag && ((this || _global).options.removeCloneOnHide || ce.length && re === e && (pe(!0, n), o("clone"), t()));
      },
      showClone: function (t) {
        var e = t.cloneNowShown,
            n = t.rootEl,
            t = t.cancel;
        (this || _global).isMultiDrag && (pe(!1, n), ue.forEach(function (t) {
          P(t, "display", "");
        }), e(), se = !1, t());
      },
      hideClone: function (t) {
        var e = this || _global,
            n = (t.sortable, t.cloneNowHidden),
            t = t.cancel;
        (this || _global).isMultiDrag && (ue.forEach(function (t) {
          P(t, "display", "none"), e.options.removeCloneOnHide && t.parentNode && t.parentNode.removeChild(t);
        }), n(), se = !0, t());
      },
      dragStartGlobal: function (t) {
        t.sortable;
        !(this || _global).isMultiDrag && re && re.multiDrag._deselectMultiDrag(), ce.forEach(function (t) {
          t.sortableIndex = B(t);
        }), ce = ce.sort(function (t, e) {
          return t.sortableIndex - e.sortableIndex;
        }), fe = !0;
      },
      dragStarted: function (t) {
        var e,
            n = this || _global,
            t = t.sortable;
        (this || _global).isMultiDrag && ((this || _global).options.sort && (t.captureAnimationState(), (this || _global).options.animation && (ce.forEach(function (t) {
          t !== ae && P(t, "position", "absolute");
        }), e = k(ae, !1, !0, !0), ce.forEach(function (t) {
          t !== ae && C(t, e);
        }), de = he = !0)), t.animateAll(function () {
          de = he = !1, n.options.animation && ce.forEach(function (t) {
            T(t);
          }), n.options.sort && ge();
        }));
      },
      dragOver: function (t) {
        var e = t.target,
            n = t.completed,
            t = t.cancel;
        he && ~ce.indexOf(e) && (n(!1), t());
      },
      revert: function (t) {
        var n,
            o,
            e = t.fromSortable,
            i = t.rootEl,
            r = t.sortable,
            a = t.dragRect;
        1 < ce.length && (ce.forEach(function (t) {
          r.addAnimationState({
            target: t,
            rect: he ? k(t) : a
          }), T(t), t.fromRect = a, e.removeAnimationState(t);
        }), he = !1, n = !(this || _global).options.removeCloneOnHide, o = i, ce.forEach(function (t, e) {
          e = o.children[t.sortableIndex + (n ? Number(e) : 0)];
          e ? o.insertBefore(t, e) : o.appendChild(t);
        }));
      },
      dragOverCompleted: function (t) {
        var e,
            n = t.sortable,
            o = t.isOwner,
            i = t.insertion,
            r = t.activeSortable,
            a = t.parentEl,
            l = t.putSortable,
            t = (this || _global).options;
        i && (o && r._hideClone(), de = !1, t.animation && 1 < ce.length && (he || !o && !r.options.sort && !l) && (e = k(ae, !1, !0, !0), ce.forEach(function (t) {
          t !== ae && (C(t, e), a.appendChild(t));
        }), he = !0), o || (he || ge(), 1 < ce.length ? (o = se, r._showClone(n), r.options.animation && !se && o && ue.forEach(function (t) {
          r.addAnimationState({
            target: t,
            rect: le
          }), t.fromRect = le, t.thisAnimationDuration = null;
        })) : r._showClone(n)));
      },
      dragOverAnimationCapture: function (t) {
        var e = t.dragRect,
            n = t.isOwner,
            t = t.activeSortable;
        ce.forEach(function (t) {
          t.thisAnimationDuration = null;
        }), t.options.animation && !n && t.multiDrag.isMultiDrag && (le = a({}, e), e = v(ae, !0), le.top -= e.f, le.left -= e.e);
      },
      dragOverAnimationComplete: function () {
        he && (he = !1, ge());
      },
      drop: function (t) {
        var e = t.originalEvent,
            n = t.rootEl,
            o = t.parentEl,
            i = t.sortable,
            r = t.dispatchSortableEvent,
            a = t.oldIndex,
            l = t.putSortable,
            s = l || (this || _global).sortable;

        if (e) {
          var c,
              u,
              d,
              h = (this || _global).options,
              f = o.children;
          if (!fe) if (h.multiDragKey && !(this || _global).multiDragKeyDown && this._deselectMultiDrag(), I(ae, h.selectedClass, !~ce.indexOf(ae)), ~ce.indexOf(ae)) ce.splice(ce.indexOf(ae), 1), ie = null, W({
            sortable: i,
            rootEl: n,
            name: "deselect",
            targetEl: ae,
            originalEvent: e
          });else {
            if (ce.push(ae), W({
              sortable: i,
              rootEl: n,
              name: "select",
              targetEl: ae,
              originalEvent: e
            }), e.shiftKey && ie && i.el.contains(ie)) {
              var p = B(ie),
                  t = B(ae);
              if (~p && ~t && p !== t) for (var g, m = p < t ? (g = p, t) : (g = t, p + 1); g < m; g++) ~ce.indexOf(f[g]) || (I(f[g], h.selectedClass, !0), ce.push(f[g]), W({
                sortable: i,
                rootEl: n,
                name: "select",
                targetEl: f[g],
                originalEvent: e
              }));
            } else ie = ae;

            re = s;
          }
          fe && (this || _global).isMultiDrag && (he = !1, (o[j].options.sort || o !== n) && 1 < ce.length && (c = k(ae), u = B(ae, ":not(." + (this || _global).options.selectedClass + ")"), !de && h.animation && (ae.thisAnimationDuration = null), s.captureAnimationState(), de || (h.animation && (ae.fromRect = c, ce.forEach(function (t) {
            var e;
            t.thisAnimationDuration = null, t !== ae && (e = he ? k(t) : c, t.fromRect = e, s.addAnimationState({
              target: t,
              rect: e
            }));
          })), ge(), ce.forEach(function (t) {
            f[u] ? o.insertBefore(t, f[u]) : o.appendChild(t), u++;
          }), a === B(ae) && (d = !1, ce.forEach(function (t) {
            t.sortableIndex !== B(t) && (d = !0);
          }), d && r("update"))), ce.forEach(function (t) {
            T(t);
          }), s.animateAll()), re = s), (n === o || l && "clone" !== l.lastPutMode) && ue.forEach(function (t) {
            t.parentNode && t.parentNode.removeChild(t);
          });
        }
      },
      nullingGlobal: function () {
        (this || _global).isMultiDrag = fe = !1, ue.length = 0;
      },
      destroyGlobal: function () {
        this._deselectMultiDrag(), f(document, "pointerup", (this || _global)._deselectMultiDrag), f(document, "mouseup", (this || _global)._deselectMultiDrag), f(document, "touchend", (this || _global)._deselectMultiDrag), f(document, "keydown", (this || _global)._checkKeyDown), f(document, "keyup", (this || _global)._checkKeyUp);
      },
      _deselectMultiDrag: function (t) {
        if (!(void 0 !== fe && fe || re !== (this || _global).sortable || t && N(t.target, (this || _global).options.draggable, (this || _global).sortable.el, !1) || t && 0 !== t.button)) for (; ce.length;) {
          var e = ce[0];
          I(e, (this || _global).options.selectedClass, !1), ce.shift(), W({
            sortable: (this || _global).sortable,
            rootEl: (this || _global).sortable.el,
            name: "deselect",
            targetEl: e,
            originalEvent: t
          });
        }
      },
      _checkKeyDown: function (t) {
        t.key === (this || _global).options.multiDragKey && ((this || _global).multiDragKeyDown = !0);
      },
      _checkKeyUp: function (t) {
        t.key === (this || _global).options.multiDragKey && ((this || _global).multiDragKeyDown = !1);
      }
    }, a(t, {
      pluginName: "multiDrag",
      utils: {
        select: function (t) {
          var e = t.parentNode[j];
          e && e.options.multiDrag && !~ce.indexOf(t) && (re && re !== e && (re.multiDrag._deselectMultiDrag(), re = e), I(t, e.options.selectedClass, !0), ce.push(t));
        },
        deselect: function (t) {
          var e = t.parentNode[j],
              n = ce.indexOf(t);
          e && e.options.multiDrag && ~n && (I(t, e.options.selectedClass, !1), ce.splice(n, 1));
        }
      },
      eventProperties: function () {
        var n = this || _global,
            o = [],
            i = [];
        return ce.forEach(function (t) {
          var e;
          o.push({
            multiDragElement: t,
            index: t.sortableIndex
          }), e = he && t !== ae ? -1 : he ? B(t, ":not(." + n.options.selectedClass + ")") : B(t), i.push({
            multiDragElement: t,
            index: e
          });
        }), {
          items: r(ce),
          clones: [].concat(ue),
          oldIndicies: o,
          newIndicies: i
        };
      },
      optionListeners: {
        multiDragKey: function (t) {
          return "ctrl" === (t = t.toLowerCase()) ? t = "Control" : 1 < t.length && (t = t.charAt(0).toUpperCase() + t.substr(1)), t;
        }
      }
    });
  }()), Bt;
});
export default exports;